import { React, useEffect, useState } from "react";
// import ProtectedRoute from "./components/ProtectedRoute";
import { NavLink, Link } from "react-router-dom";
import PST from "./img/ps.png"
import Logo from "./img/logo.png"
import Solvea from "./img/solvea.png"
import TichyTan from "./img/tichy-tan.png"
function Home() {
  useEffect(() => {
    document.title = "180Daraga | Home";
  }, []);
  return (
    <div className="home">
      <div className="container">
        <nav>
          <div className="logo"><img src={Logo} alt="" srcset="" /></div>
          <h1>180 Daraga PST</h1>
          <div className="PST-logo"><img src={PST} alt="" srcset="" /></div>
        </nav>
        <div className="levels">
          <div className="level0">
            <div className="title">
              <h2>Level 0</h2>
              <img src={Solvea} alt="" srcset="" />
            </div>
            <div className="groups">
              <NavLink to="/0/g1" exact>Group 1</NavLink>
              <NavLink to="/0/g2" exact>Group 2</NavLink>
            </div>
          </div>
          <div className="level1">
            <div className="title">
              <h2>Level 1</h2>
              <img src={TichyTan} alt="" srcset="" />
            </div>
            <div className="groups">
              <NavLink to="/1/g1" exact>Group 1</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
