import React from 'react'

export default function StandingsCard(props) {
  return (
      <div className='standings_card' style={{ backgroundColor: `${props.color}` }}>
        <div className="info">
          <p><span style={{color:`${props.color}`}}>{props.number}</span>{props.name}</p>
        </div>
        <div className="points">
          <p>{props.points}</p>
        </div>
    </div>
  )
}
