import React from "react";
import Home from "./Home.jsx"
// import Login from "./pages/Login"
// import ProtectedRoute from "./components/ProtectedRoute"
import Score from "./pages/Score.jsx";
// import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
function App() {
  console.log = () => {
    return 1;
  };
  return (
    <div className="App">
      <Router>
        <Route path="/" exact component={Home} />
        <Route path="/1/g1" exact>
          <Score level="1" group="1" />
        </Route>
        <Route path="/0/g1" exact>
          <Score level="0" group="1" />
        </Route>      <Route path="/0/g2" exact>
          <Score level="0" group="2" />
        </Route>
      </Router>
    </div>
  );
}

export default App;