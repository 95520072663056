import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import { UserProvider } from "./context/UserContext";
import "./sass/main.scss";
import axios from "axios";
import { BrowserRouter as Router } from "react-router-dom";

// axios.defaults.baseURL = "http://localhost:5550/";
axios.defaults.baseURL = "https://api.180daraga.com";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router forceRefresh={true}>
      {/* <UserProvider> */}
        <App />
      {/* </UserProvider> */}
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals