import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from "axios";
import Logo from "../img/logo.png";
import PST from "../img/ps.png";
import StandingsCard from '../components/StandingsCard';
export default function Score(props) {
    // #010142
    const colors = ["#010142", "#2E58F6", "#D5CE41"];
    const [isLoading, setIsLoading] = useState(true);
    const [students, setStudents] = useState([]);
    const getStudents = () => {
        axios
            .get(`/api/events/problemsolving23`)
            .then((response) => {
                setStudents(response.data.message);
                console.log(response.data.message);
                setIsLoading(false);
            })
            .catch((err) => console.log(err));
    }
    useEffect(getStudents, []);
    useEffect(() => {
        document.title = "180Daraga | Score";
      }, []);
    return (
        <div className='score'>
            <div className="container">
                <nav>
                    <div className="logo"><img src={Logo} alt="" srcset="" /></div>
                    <h1>180 Daraga PST</h1>
                    <div className="PST-logo"><img src={PST} alt="" srcset="" /></div>
                </nav>
                {
                    isLoading&&(
                        <div className="spinner-container">
                        <div className="loading-spinner">
                        </div>
                    </div>
                    )
                }
                {
                    !isLoading&&(
                        //.filter((el)=>el.comment === "1" && el.data2 === "1")
                    students.filter((el) => el.comment === props.group && el.data2 === props.level).sort((a, b) => (+a.data3) - (+b.data3)).reverse().map((el, index) => {
                        return (
                            <>
                                <StandingsCard number={index + 1} color={colors[0]} name={el.name.split(" ").slice(0, 2).join(" ")} points={el.data3} />
                            </>
                        )
                    })
                    )
                }
            </div>
        </div>
    )
}
